<template>
    <div>
        <clini-profile-component/>
    </div>
</template>

<script>
    import CliniProfileComponent from "@/components/hospitals-clinics/ClinicProfileComponent";
    
    export default {
        name: "ClinicProfile",
        title: "Perfil de la Clínica | Turismo BC",
        components: { CliniProfileComponent }
    }
</script>

<style scoped>

</style>